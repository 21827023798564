@font-face {
  font-family: 'mousesrs';
  src: url('../fonts/mousesrs.eot?39497637');
  src: url('../fonts/mousesrs.eot?39497637#iefix') format('embedded-opentype'),
       url('../fonts/mousesrs.woff2?39497637') format('woff2'),
       url('../fonts/mousesrs.woff?39497637') format('woff'),
       url('../fonts/mousesrs.ttf?39497637') format('truetype'),
       url('../fonts/mousesrs.svg?39497637#mousesrs') format('svg');
  font-weight: normal;
  font-style: normal;
}

.m-mousesrs-help-button [class^="icon-"]:before, .m-mousesrs-help-button [class*=" icon-"]:before {
  font-family: "mousesrs";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-mousesrs-help-button .icon-help:before { content: '\e800'; }
