.m-control.m-container.m-mousesrs-container {
    padding: 6px 12px 3px;
    color: white;
    height: 16px;
    border-radius: 20px;
    white-space: nowrap;
    order: 4;
    background-color: rgb(113, 167, 211) !important;
    margin-right: 10px;
    margin-bottom: 10px;
    position: absolute;
    left: calc(50vw - 100px);
    top: calc(100vh - 35px);
    display: flex;
    border: 2px solid white;
}

.m-control.m-container.m-mousesrs-container .m-mouse-srs {
    font-family: 'Muli', sans-serif !important;
    font-size: 12px;
}

.m-control.m-container.m-mousesrs-container .g-cartografia-flecha::before {
    margin-right: 5px;
}

.m-mousesrs-srs-container {
    text-align: center;
    width: 100%;
    margin: 1rem 0 1rem 1.2rem;
    display: flex;
    justify-content: center;
}

.m-mousesrs-srs-container select {
    background-color: white;
    padding: 7px 10px;
    cursor: pointer;
}

.m-mousesrs-srs-container .m-mousesrs-help-button {
    margin-left: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-mousesrs-srs-container .m-mousesrs-help-button a {
    font-size: 25px;
    color: #71a7d3 !important;
    text-decoration: none;
}

.m-mousesrs-pointer {
    cursor: pointer;
}

@media (max-width: 768px) {
    .m-control.m-container.m-mousesrs-container {
        display: none;
    }
}